export const ROUTER_PATH = {
    login: 'auth/login',
    forgotPassword: 'auth/forgot-password',
    dashboard: 'dashboard',
    addCertificate: 'certificate/create',
    listCertificate: 'certificate/list',
    detailCertificate: 'certificate/detail',
    editCertificate: 'certificate/update',
    revisionCertificate: 'certificate/revision',
    addPrincipal: 'principal/create',
    listPrincipal: 'principal/list',
    detailPrincipal: 'principal/detail',
    editPrincipal: 'principal/update',
    addInsurance: 'insurance/create',
    listInsurance: 'insurance/list',
    detailInsurance: 'insurance/detail',
    editInsurance: 'insurance/update',
    addSubagent: 'sub-agent/create',
    listSubagent: 'sub-agent/list',
    detailSubagent: 'sub-agent/detail',
    editSubagent: 'sub-agent/update',
    listUserManagement: 'user-management/list',
    detailUserManagement: 'user-management/detail',
    addBlanko: 'blanko/create',
    listBlanko: 'blanko/list',
    detailBlanko: 'blanko/detail',
    distributeBlanko: 'blanko/distribute',
    recapBlanko: 'blanko/recap',
    recapBlankoInsurance: 'blanko/recap/insurance',
    recapBlankoHistory: 'blanko/recap/history',
    financialStatementInsuranceList: 'financial-statement/list/insurance-product',
    financialStatementInsuranceDetail: 'financial-statement/detail/insurance-product',
    financialStatementProductionReport: 'financial-statement/production-report/list',
    financialStatementProductionReportDetail: 'financial-statement/production-report/detail'

}
